import { FunctionComponent, PropsWithChildren } from "react";
import {
  supplementalRolePermission,
  useSupplementalRolePermissionDetails,
  userSupplementalRole,
} from "../../api-hooks/useSupplementalRolePermissionDetails";

export const checkPermission = (
  permissionCode: string,
  userRoles: userSupplementalRole[],
  permissionRoles: supplementalRolePermission[],
): boolean => {
  return permissionRoles.some(
    (role) =>
      role.PermissionCode === permissionCode &&
      userRoles.some(
        (userRole) => userRole.SupplementalRoleId === role.SupplementalRoleId,
      ),
  );
};

export const PermissionRequired: FunctionComponent<
  PropsWithChildren & { silent: boolean; permittedRoles: string[] }
> = ({ children, silent, permittedRoles }) => {
  const userRoles = useSupplementalRolePermissionDetails();

  return (
    <>
      {userRoles.status === "success" &&
      userRoles.data.SupplementalRoleDetails.some((el) =>
        permittedRoles.includes(el.PermissionCode),
      ) ? (
        children
      ) : silent ? (
        <></>
      ) : (
        "Unauthorized"
      )}
    </>
  );
};

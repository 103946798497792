import Done from "@mui/icons-material/Done";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useAtomValue } from "jotai";
import { FunctionComponent, ReactElement } from "react";
import { useUserDetails } from "../../api-hooks/auth/useUserDetails";
import { clientAccountViewAtom } from "../persistedUserPreferences";
import style from "./InlineNotification.module.css";

export const InlineNotification: FunctionComponent<{
  message: string | ReactElement;
  type: "success" | "loading" | "error" | "info";
  textDisplayOption?: "unbound";
}> = ({ message, type, textDisplayOption }) => {
  const userDetails = useUserDetails();

  const clientAppView = useAtomValue(clientAccountViewAtom);

  if (type === "error" && typeof message === "string" && message.length < 3) {
    message = "Something went wrong, please try again.";
  }

  if (typeof message !== "string") {
    return message;
  }

  return (
    <p className={[style.notification, style[type]].join(" ")}>
      {type === "success" && <Done />}
      {type === "loading" &&
        clientAppView === -1 &&
        (userDetails.status === "success"
          ? userDetails.data.userType !== "Affiliate"
          : true) && (
          <img
            alt="Loading spinner"
            className={style.loader}
            src="/images/animated.png"
          />
        )}
      {type === "info" && <InfoOutlined />}
      {type === "error" && <ErrorOutline />}
      <span
        title={typeof message === "string" ? message : undefined}
        className={[
          style.text,
          textDisplayOption ? style[textDisplayOption] : "",
        ].join(" ")}
      >
        {message}
      </span>
    </p>
  );
};
